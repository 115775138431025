import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export default function BlogPage({data}) {
    return (
      <Layout>
        <Seo title="Blog" />
        <h2>Coming soon...</h2>
        {/*data.allMarkdownRemark.edges.map(({ node }) => (
            <div>
                <Link to={node.fields.slug}> Blog Post {node.id}
                </Link>
            </div>
        ))*/}
      </Layout>
    )
}

export const query = graphql`query{
    allMarkdownRemark {
        totalCount
        edges {
            node {
                frontmatter {
                    title
                }
                fields {
                    slug
                }
                id
            }
        }
    }
}`


